html,
body,
#root {
  height: 100%;
}

.katex-html {
  display: none;
}

iframe.ql-video {
  width: 720px;
  height: 405px;
}